<template>
  <main id="content" role="main">
    <div class="container py-3">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0"></h1>
          <div class="mt-1"></div>
        </div>
        <div class="d-flex flex-wrap"></div>
      </div>
    </div>
    <div id="ContentPlaceHolder1_divSummary" class="container space-bottom-2">
      <div class="card">
        <div
          class="
            card-header
            d-flex
            justify-content-between
            align-items-center
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li id="ContentPlaceHolder1_li_transactions" class="nav-item">
              <a
                class="nav-link active"
                href="#"
                data-toggle="tab"
                onclick="javascript:updatehash('');"
                >Accounts</a
              >
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="table-responsive mb-2 mb-md-0" v-loading="loading">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Accounts</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Percent</th>
                      <th scope="col">Memo</th>
                      <th scope="col">Logo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in accounts" :key="item._id">
                      <td>
                        <a
                          class="
                            hash-tag-my
                            text-truncate
                            myFnExpandBox_searchVal
                          "
                          href="#"
                          @click.prevent="showAddress(item.address)"
                        >
                          <span>{{ item.address }}</span>
                        </a>
                      </td>
                      <td>
                        <a
                          class="hash-tag text-truncate myFnExpandBox_searchVal"
                        >
                          {{ formatNumber(item.balance, 4) }}
                        </a>
                      </td>
                      <td>
                        <a
                          class="hash-tag text-truncate myFnExpandBox_searchVal"
                        >
                          {{ formatNumber(item.percent, 2) }}%
                        </a>
                      </td>
                      <td>
                        <a
                          class="hash-tag text-truncate myFnExpandBox_searchVal"
                        >
                          {{ item.memo }}
                        </a>
                      </td>
                      <td>
                        <img
                          width="25"
                          v-if="item.logo"
                          :src="item.logo"
                          alt=""
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                id="ContentPlaceHolder1_topPageDiv"
                class="d-md-flex justify-content-between mb-4"
              >
                <p class="mb-2 mb-md-0"></p>

                <nav aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li class="page-item" data-toggle="tooltip" title="">
                      <a
                        class="page-link"
                        href="javascript:void(0)"
                        v-on:click="execPage(-1)"
                        aria-label="Previous"
                      >
                        <span aria-hidden="True">
                          <i class="fa fa-chevron-left small"></i>
                        </span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>

                    <li Class="page-item disabled">
                      <span Class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          currentPage
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          parseInt(total / pageNum) + 1
                        }}</strong>
                      </span>
                    </li>
                    <li class="page-item" data-toggle="tooltip" title="">
                      <a
                        class="page-link"
                        href="javascript:void(0)"
                        v-on:click="execPage(1)"
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="notes"
              role="tabpanel"
              aria-labelledby="notesnotes-tab"
            >
              <div
                class="table-responsive mb-2"
                style="overflow: auto; -webkit-overflow-scrolling: touch"
              ></div>
            </div>
            <div
              class="tab-pane fade"
              id="internaltx"
              role="tabpanel"
              aria-labelledby="internaltx-tab"
            >
              <div class="d-md-flex justify-content-between mb-4">
                <div class="mb-2 mb-lg-0 mt-1">
                  <div class="d-flex">
                    <div
                      class="ml-2"
                      id="divswitch"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="Toggle between Simplified and Advanced view. The 'Advanced' view also shows zero value HT transfers, while the 'Simple' view only shows HT transfers with value. Name tags integration is not available in advanced view"
                    >
                      <label
                        class="checkbox-switch mb-0"
                        id="labelswitch"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title=""
                        title=""
                      >
                        <input
                          type="checkbox"
                          class="checkbox-switch__input"
                          id="customSwitch1"
                          onclick="toggleMode();"
                        />
                        <span class="checkbox-switch__slider"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <nav aria-label="page navigation">
                  <a
                    id="ContentPlaceHolder1_linkShowMoreInternalTx"
                    class="btn btn-xss btn-primary"
                    data-toggle="tooltip"
                    title="View all transactions"
                    data-toggle-second="tooltip"
                    >View All</a
                  >
                </nav>
              </div>

              <div
                id="ContentPlaceHolder1_divinternaltxtable"
                class="table-responsive mb-2 mb-md-0"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" width="1">Parent Txn Hash</th>
                      <th scope="col" width="1">Block</th>
                      <th scope="col">
                        <span>
                          <a
                            href="javascript:;"
                            onclick="switchAgeToDateTimeInAddPage(this)"
                            id="lnkIntAgeDateTime"
                          ></a
                        ></span>
                      </th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                      <th scope="col" width="1"></th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-3
                  mb-4
                "
              >
                <div class="d-flex align-items-center text-secondary"></div>
                <div class="small text-muted"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import route from "../router/index";
import { getUrlKey } from "../../src/assets/js/utils.js";

export default {
  name: "Accounts",
  data() {
    return {
      accounts: [],
      currentPage: 1,
      pageNum: 20,
      total: 0,
      loading: false,
    };
  },
  mounted() {
    this.currentPage = parseInt(getUrlKey("p"));
    if (this.currentPage <= 0 || isNaN(this.currentPage)) {
      this.currentPage = 1;
    }
    this.loading = true;
    PostNet.PostWith(
      PostNetUrl.accountList,
      (info) => {
        this.loading = false;
        this.accounts = info.data.data;
        this.total = info.data.count;
      },
      (res) => {
        this.loading = false;
      },
      { page: this.currentPage, pageNum: this.pageNum }
    );
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    // 页面的方法
    showAddress(addr) {
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
    },
    execPage(page) {
      this.currentPage = parseInt(this.currentPage) + parseInt(page);
      if (isNaN(this.currentPage)) {
        this.currentPage = 1;
      }
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      let totalPage = parseInt(this.total / this.pageNum) + 1;
      if (this.currentPage > totalPage) {
        this.currentPage = totalPage;
        return;
      }
      this.loading = true;
      PostNet.PostWith(
        PostNetUrl.accountList,
        (info) => {
          this.loading = false;
          this.accounts = info.data.data;
          this.total = info.data.count;
        },
        (err) => {
          this.loading = false;
        },
        { page: this.currentPage, pageNum: this.pageNum }
      );
    },
  },
};
</script>
